import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import React, { useState } from "react";
import { IMAGE_TYPES, ImageCoordinates, ListImageItem } from "queries/devices/images";
import NothingToShow from "components/NothingToShow";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import LoadingImage from "components/Image/LoadingImage";
import DetectionCountChip from "components/deviceChips/DetectionCountChip";
import DetectionCountDeltaChip from "components/deviceChips/DetectionCountDeltaChip";
import CleanedButton from "components/deviceChips/CleanedButton";
import { UseQueryResult } from "@tanstack/react-query";
import { ImageView } from "components/Image/ImageView";
import { ImageEdit } from "components/Image/ImageEdit";

type ValidatedImageProps = {
  imageQuery: UseQueryResult<ListImageItem[], unknown>;
};

const ValidatedImage: React.FC<ValidatedImageProps> = ({ imageQuery }) => {
  const { isLoading } = imageQuery;
  const data = imageQuery.data?.[0];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const [isEditing, setIsEditing] = useState(false);
  const [initialCoordinates, setInitialCoordinates] = useState<ImageCoordinates>([]);

  let content = <NothingToShow />;
  if (isLoading) {
    content = <LoadingImage />;
  } else if (data && isEditing) {
    content = (
      <ImageEdit
        id={data.id}
        // always edit raw image, we draw coordinates on it, don't use processed image
        // for that as in that case we won't be able to delete bounding boxes.
        type={IMAGE_TYPES.raw}
        saveAs={IMAGE_TYPES.validated}
        onClose={() => {
          setIsEditing(false);
        }}
        initialCoordinates={initialCoordinates}
        // make as separate request to also save the coordinates and detection count (number of coordinates)
        mode="validation"
      />
    );
  } else if (data && data.validated_blob_name) {
    content = <ImageView id={data.id} type={IMAGE_TYPES.validated} />;
  }

  return (
    <Grid item sm={12} lg={6}>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", paddingTop: "9px" }}>
          <Typography variant="overline" component="div" sx={{ flex: 1 }}>
            Current validated
          </Typography>
          <Stack direction="row" spacing={2}>
            <DetectionCountChip value={data?.detection_count} />
            <DetectionCountDeltaChip value={data?.detection_count_delta} />

            <CleanedButton
              id={data?.id}
              handleCleanupClick={() => {
                imageQuery.refetch();
              }}
            />
          </Stack>
          {!isEditing && (
            <>
              <Tooltip title="Create current validated..." arrow>
                <IconButton
                  id="button-create-validated"
                  aria-controls={open ? "menu-create-validated" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <AddCircleOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-create-validated"
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenu}
                MenuListProps={{
                  "aria-labelledby": "button-create-validated",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setIsEditing(true);
                    // reset coordinates, user wants to start from scratch
                    setInitialCoordinates([]);
                    closeMenu();
                  }}
                  disabled={!data?.raw_blob_name}
                  aria-disabled={!data?.raw_blob_name}
                >
                  From raw
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsEditing(true);
                    // set coordinates to original ai coordinates
                    setInitialCoordinates(data?.original_ai_coordinates ?? []);
                    closeMenu();
                  }}
                  disabled={!(data?.original_ai_coordinates?.length ?? 0)}
                  aria-disabled={!(data?.original_ai_coordinates?.length ?? 0)}
                >
                  From processed
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsEditing(true);
                    // set coordinates to ones saved in image
                    setInitialCoordinates(data?.coordinates ?? []);
                    closeMenu();
                  }}
                  // enabled if we have already annotated any coordinates
                  disabled={!(data?.coordinates.length ?? 0) || !data?.is_validated}
                  aria-disabled={!(data?.coordinates.length ?? 0) || !data?.is_validated}
                >
                  From validated
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
        {content}
      </Paper>
    </Grid>
  );
};

export default ValidatedImage;
