import { useQuery } from "@tanstack/react-query";
import { IMAGE_TYPES, ImageCoordinates, downloadImage } from "queries/devices/images";
import React from "react";
import { SECONDS_30 } from "utils/time";
import { Typography } from "@mui/material";
import ValidationProvider, { ValidationProviderProps } from "context/ValidationProvider";
import ImageEditor from "./ImageEditor";
import LoadingImage from "./LoadingImage";

export type ImageProps = {
  id: string;
  type: IMAGE_TYPES;
  title?: string;
  width?: number;
  initialCoordinates: ImageCoordinates;
  // The image edit has two modes: validation and delta
  // Validation mode is the one mostly used. It also marks images as validated and saves the
  // marked bounding boxes and count of bounding boxes (detection count).
  mode: "validation" | "delta";
} & Pick<ValidationProviderProps, "saveAs" | "onClose">;

export const ImageEdit: React.FC<ImageProps> = ({
  id,
  type,
  initialCoordinates,
  onClose,
  saveAs,
  title,
  width: defaultWidth,
  mode,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [width, setWidth] = React.useState<number>(defaultWidth ?? 0);

  React.useEffect(() => {
    const { current } = ref;
    const fn = () => {
      setWidth(current?.offsetWidth ?? 0);
    };

    window.addEventListener("resize", fn);
    fn();

    return () => {
      window.removeEventListener("resize", fn);
    };
  }, []);

  const { data, isFetching, error } = useQuery({
    queryFn: () => downloadImage({ id: id as string, type }),
    queryKey: ["download-image", { id, type }],
    staleTime: SECONDS_30,
    enabled: !!id,
  });

  let content = null;
  if (isFetching) {
    content = <LoadingImage showTitle={!!title} />;
  } else if (data) {
    content = (
      <ValidationProvider
        id={id}
        saveAs={saveAs}
        onClose={onClose}
        initialCoordinates={initialCoordinates}
        mode={mode}
      >
        <ImageEditor data={data} width={width} />
      </ValidationProvider>
    );
  } else {
    content = <>{String(error)}</>;
  }

  return (
    <div className="image" ref={ref}>
      {title && <Typography variant="overline">{title}</Typography>}
      {content}
    </div>
  );
};
