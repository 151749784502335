import React from "react";
import { Chip, Tooltip } from "@mui/material";
import EmojiNatureOutlinedIcon from "@mui/icons-material/EmojiNatureOutlined";

type DetectionCountChipProps = {
  value?: number | null;
};

const DetectionCountChip: React.FC<DetectionCountChipProps> = ({ value }) => {
  return (
    <Tooltip title="Detection count" arrow>
      <Chip icon={<EmojiNatureOutlinedIcon />} label={value} variant="outlined" />
    </Tooltip>
  );
};

export default DetectionCountChip;
