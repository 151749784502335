import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { DetailDeviceItem } from "queries/devices";
import { IMAGE_TYPES, listImages } from "queries/devices/images";
import React from "react";
import { MINUTE_1 } from "utils/time";
import NothingToShow from "components/NothingToShow";
import LoadingImage from "components/Image/LoadingImage";
import { ImageView } from "components/Image/ImageView";
import { StepperInfo, Stepper } from "./ValidationPanel";

type ImagePanelProps = { device: DetailDeviceItem };

const ImagePanel: React.FC<ImagePanelProps> = ({ device }) => {
  const [currentTimestamp, setCurrentTimestamp] = React.useState<undefined | number>(undefined);
  const params = React.useMemo(
    () => ({
      device__id: device.id,
      page: 1,
      page_size: 2,
      ordering: "-created_at" as const,
      created_at__lte: currentTimestamp,
    }),
    [currentTimestamp, device.id]
  );
  const imageQuery = useQuery({
    queryKey: ["list-images", params],
    queryFn: async () => (await listImages(params)).data,
    staleTime: MINUTE_1,
  });
  let content = <NothingToShow />;
  if (imageQuery.isLoading) {
    content = <LoadingImage />;
  } else if (imageQuery.data?.[0]) {
    content = <ImageView id={imageQuery.data[0].id} type={IMAGE_TYPES.validated} />;
  }

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        // height: "min(500px, calc(100vw / 3))",
        mb: 3,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{ alignItems: "center" }}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Typography variant="overline">Image panel</Typography>
        <StepperInfo imageQuery={imageQuery} />
        <Stepper
          imageQuery={imageQuery}
          deviceId={device.id}
          setCurrentTimestamp={setCurrentTimestamp}
        />
      </Stack>
      <Box className="flexContainer" sx={{ display: "flex", justifyContent: "center" }}>
        <Box className="imageContainer" sx={{ width: 600 }}>
          {content}
        </Box>
      </Box>
    </Paper>
  );
};
export default ImagePanel;
