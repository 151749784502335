import React from "react";
import { Box, Button } from "@mui/material";
import { useValidation } from "context/ValidationProvider";
import { LoadingButton } from "@mui/lab";

const Toolbar: React.FC = () => {
  const {
    annotations,
    onClose,
    onClear,
    onSave,
    isSaving,
    coordinateIndexToDelete,
    deleteSelectedCoordinate,
  } = useValidation();

  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <Button variant="outlined" size="small" sx={{ m: 1 }} onClick={onClose}>
        CLOSE
      </Button>

      <Box>
        <Button
          variant="outlined"
          size="small"
          sx={{ m: 1 }}
          onClick={() => {
            deleteSelectedCoordinate();
          }}
          disabled={coordinateIndexToDelete === null}
          aria-disabled={coordinateIndexToDelete === null}
        >
          REMOVE SELECTED
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{ m: 1 }}
          onClick={onClear}
          disabled={annotations.length === 0}
        >
          CLEAR ALL
        </Button>
      </Box>

      <LoadingButton
        loading={isSaving}
        variant="contained"
        size="small"
        sx={{ m: 1 }}
        onClick={onSave}
      >
        SAVE
      </LoadingButton>
    </Box>
  );
};

export default Toolbar;
