import { Box, Grid, IconButton, Menu, MenuItem, Paper, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { IMAGE_TYPES, ImageCoordinates, ListImageItem } from "queries/devices/images";
import NothingToShow from "components/NothingToShow";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import LoadingImage from "components/Image/LoadingImage";
import { ImageView } from "components/Image/ImageView";
import { ImageEdit } from "components/Image/ImageEdit";

type DeltaImageProps = {
  isLoading?: boolean;
  data?: ListImageItem;
};

const DeltaImage: React.FC<DeltaImageProps> = ({ isLoading, data }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const [isEditing, setIsEditing] = useState(false);
  const [initialCoordinates, setInitialCoordinates] = useState<ImageCoordinates>([]);

  let content = <NothingToShow />;
  if (isLoading) {
    content = <LoadingImage />;
  } else if (data && isEditing) {
    content = (
      <ImageEdit
        id={data.id}
        // always start with raw image, we draw coordinates on it, don't use processed image
        // for that as in that case we won't be able to delete bounding boxes.
        type={IMAGE_TYPES.raw}
        saveAs={IMAGE_TYPES.delta}
        onClose={() => {
          setIsEditing(false);
        }}
        initialCoordinates={initialCoordinates}
        // don't save the coordinates (we only store coordinates of all bounding boxes, but not for delta)
        mode="delta"
      />
    );
  } else if (data && data.delta_blob_name) {
    // if we have an image to show
    content = <ImageView id={data.id} type={IMAGE_TYPES.delta} />;
  }

  return (
    <Grid item sm={12} lg={6}>
      <Paper elevation={3} sx={{ p: 3, height: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="overline" component="div" sx={{ flex: 1 }}>
            Current delta
          </Typography>
          {!isEditing && (
            <>
              <Tooltip title="Create current delta..." arrow>
                <IconButton
                  id="button-create-delta"
                  aria-controls={open ? "menu-create-delta" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <AddCircleOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-create-delta"
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenu}
                MenuListProps={{
                  "aria-labelledby": "button-create-delta",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setIsEditing(true);
                    // reset coordinates, user wants to start from scratch
                    setInitialCoordinates([]);
                    closeMenu();
                  }}
                  disabled={!data?.raw_blob_name}
                  aria-disabled={!data?.raw_blob_name}
                >
                  From raw
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsEditing(true);
                    // set coordinates to original ai coordinates
                    setInitialCoordinates(data?.original_ai_coordinates ?? []);
                    closeMenu();
                  }}
                  disabled={!(data?.original_ai_coordinates?.length ?? 0)}
                  aria-disabled={!(data?.original_ai_coordinates?.length ?? 0)}
                >
                  From processed
                </MenuItem>
                {/* this might not make sense for delta images (as validated will show all pests, not only changes). */}
                <MenuItem
                  onClick={() => {
                    setIsEditing(true);
                    // set coordinates to ones saved in image
                    setInitialCoordinates(data?.coordinates ?? []);
                    closeMenu();
                  }}
                  // enabled if we have already annotated any coordinates
                  disabled={!(data?.coordinates.length ?? 0) || !data?.is_validated}
                  aria-disabled={!(data?.coordinates.length ?? 0) || !data?.is_validated}
                >
                  From validated
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
        {content}
      </Paper>
    </Grid>
  );
};

export default DeltaImage;
