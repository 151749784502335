import React from "react";
import { Image } from "react-konva";
import { Image as ImageRef } from "konva/lib/shapes/Image";

type URLImageProps = {
  obj: Blob | MediaSource;
  onWidthChange?: (width: number) => void;
};
const URLImage = ({ obj, onWidthChange }: URLImageProps) => {
  const imageRef = React.useRef<ImageRef>(null);

  const stageWidth = imageRef.current?.getStage()?.width();

  const autoSize = React.useCallback(() => {
    const imageWidth = imageRef.current?.width();
    const imageHeight = imageRef.current?.height();
    const stage = imageRef.current?.getStage();
    const stageWidth = stage?.width();

    if (imageWidth && stageWidth && imageHeight && stage) {
      const ratio = stageWidth / imageWidth;
      stage.scale({ x: ratio, y: ratio });
      stage.height(imageHeight * ratio);
      onWidthChange?.(imageWidth);
    }
  }, [onWidthChange]);

  React.useEffect(() => {
    autoSize();
  }, [autoSize, stageWidth]);

  const image = React.useMemo(() => {
    const url = URL.createObjectURL(obj);
    const image = new window.Image();
    image.src = url;
    image.onload = autoSize;
    return image;
  }, [autoSize, obj]);

  return <Image image={image} ref={imageRef} />;
};

export default URLImage;
