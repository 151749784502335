import React from "react";
import { Layer, Stage } from "react-konva";
import URLImage from "./URLImage";

export type ImageReaderProps = {
  data: Blob;
  width: number;
};

const ImageReader: React.FC<ImageReaderProps> = ({ width, data }) => {
  return (
    <Stage width={width} height={300}>
      <Layer>
        <URLImage obj={data} />
      </Layer>
    </Stage>
  );
};

export default ImageReader;
